<template>
  <div>
    <el-card>
      <el-row align="middle" type="flex" justify="space-between" style="width: 100%">
        <el-col :xs="24" :sm="12">
          <el-breadcrumb separator-class="el-icon-arrow-right" class="banner">
            <el-breadcrumb-item :to="{ path: '/home/one' }">首页</el-breadcrumb-item>
            <el-breadcrumb-item v-for="(item, index)
                in $route.matched" :key="index">{{ item.name }}</el-breadcrumb-item>
          </el-breadcrumb>
        </el-col>
        <el-button style="float: right; margin-top: -8px;" size="medium"
          v-if="this.$route.matched[1].path == '/tunnelm/manage'" type="primary" @click="xznode = true"
          plain>添加隧道</el-button>
      </el-row>
    </el-card>
    <el-dialog :visible.sync="dialogVisible" @open="onOpen" @close="onClose" title="添加一个隧道" :width="dialogWidth">
      <el-alert v-if="formData.domainNameLabel === '自定义' && haschax === false" style="margin-top: -20px;" title="您尚未查询域名是否解析成功"
        :closable="false" type="info" center show-icon>
      </el-alert>
      <el-alert v-if="formData.domainNameLabel === '自定义' && hasError === true" style="margin-top: -20px;" title="尚未查询到对应域名解析"
        :closable="false" type="warning" center show-icon>
      </el-alert>
      <el-alert v-if="formData.domainNameLabel === '自定义' && hasCname === true" style="margin-top: -20px;" title="已查询到对应CNAME解析"
        :closable="false" type="success" center show-icon>
      </el-alert>
      <el-alert v-if="formData.domainNameLabel === '自定义' && hasSrv === true" style="margin-top: -20px;" title="已查询到对应SRV解析"
        :closable="false" type="success" center show-icon>
      </el-alert>
      <el-row :gutter="15" style="margin-top: 15px;">
        <el-form ref="tunnelForm" :model="formData" :rules="rules" size="medium" label-width="100px"
          label-position="left">
          <el-col :xs="24" :sm="12">
            <el-form-item label="隧道名称" prop="name">
              <el-input v-model="formData.name" placeholder="请输入隧道名称" clearable :style="{ width: '100%' }">
              </el-input>
            </el-form-item>
          </el-col>
          <el-col :xs="24" :sm="12">
            <el-form-item label="本地IP" prop="localip">
              <el-input v-model="formData.localip" placeholder="请输入本地IP" clearable :style="{ width: '100%' }">
              </el-input>
            </el-form-item>
          </el-col>
          <el-col :xs="24" :sm="12">
            <el-form-item label="节点选择" prop="node">
              <el-select v-model="formData.node" @click.native="openNodeDropdown"
                :style="{ width: '100%', cursor: 'pointer' }" :placeholder="formData.node || '请选择节点'">
                <el-option value="option" :label="formData.node">
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :xs="24" :sm="12">
            <el-form-item label="端口类型" prop="type">
              <el-select v-model="formData.type" placeholder="请选择端口类型" clearable :style="{ width: '100%' }"
                @change="handleTypeChange">
                <el-option v-for="(item, index) in typeOptions" :key="index" :label="item.label" :value="item.value"
                  :disabled="item.disabled"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :xs="24" :sm="12">
            <el-form-item label="内网端口" prop="nport">
              <el-select v-model="formData.nport" filterable allow-create clearable placeholder="请输入内网端口"
                style="width: 100%;">
                <el-option v-for="item in intranetPort" :key="item.intranetPortValue" :label="item.intranetPortLabel"
                  :value="item.intranetPortLabel" :disabled="item.intranetPortDisabled">
                  <span style="float: left">{{ item.intranetPortLabel }}</span>
                  <span style="float: right; color: #8492a6; font-size: 13px">{{ item.intranetPortValue }}</span>
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :xs="24" :sm="12">
            <el-form-item v-if="formData.type === 'http' || formData.type === 'https'" label='域名类型'
              prop="domainNameLabel">
              <el-select v-model="formData.domainNameLabel" placeholder="请选择域名类型" style="width: 100%;" clearable>
                <el-option label="自定义" value="自定义">
                  <span style="float: left">自定义</span>
                  <span style="float: right; color: #8492a6; font-size: 13px">使用自己的域名</span></el-option>
                <el-option label="免费域名" value="免费域名" disabled>
                  <span style="float: left">免费域名</span>
                  <span style="float: right; color: #8492a6; font-size: 13px">暂不可用，5月开放</span>
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item v-else label='外网端口' prop="dorp">
              <el-input v-model="formData.dorp" :placeholder="getPlaceholder(formData.type)" clearable
                :style="{ width: '100%' }">
              </el-input>
            </el-form-item>
          </el-col>
          <div v-if="formData.domainNameLabel === '自定义'">
            <el-col :xs="24" :sm="18">
              <el-form-item label="域名" prop="dorp">
                <el-input v-model="formData.dorp" placeholder="请输入您的域名" clearable :style="{ width: '100%' }"></el-input>
              </el-form-item>
            </el-col>
            <el-col :xs="24" :sm="6">
              <el-button style="width: 100%;" @click="handleQuery" v-loading="loading_handleQuery">解析查询</el-button>
            </el-col>
          </div>
          <el-col :span="24" v-if="formData.domainNameLabel === '自定义'">
            <span class="jz">请将 {{ this.formData.dorp }} 域名通过<el-link
                href="https://docs.chcat.cn/docs/chmlfrp/%E4%BD%BF%E7%94%A8%E6%96%87%E6%A1%A3/%E5%9F%9F%E5%90%8D%E8%A7%A3%E6%9E%90/cname"
                target="_blank" type="primary" :underline="false"> CNAME </el-link>解析至 {{ this.NodeDomain }}</span>
          </el-col>
          <div v-if="formData.domainNameLabel === '免费域名'">
            <el-col :xs="24" :sm="12">
              <el-form-item label="请选择" prop="choose">
                <el-select style="width: 100%;" v-model="formData.choose" placeholder="请选择使用已有或新建">
                  <el-option label="已有域名" value="已有域名"></el-option>
                  <el-option label="新建域名" value="新建域名"></el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :xs="24" :sm="12">
              <el-form-item v-if="formData.choose === '已有域名'" label="已有域名" prop="dorp">
                <el-select style="width: 100%;" v-model="formData.dorp" filterable placeholder="请选择您的域名">
                  <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
                  </el-option>
                </el-select>
              </el-form-item>
              <el-form-item v-if="formData.choose === '新建域名'" label="新建域名" prop="dorp">
                <el-input placeholder="请输入域名前缀" v-model="formData.dorp">
                  <template slot="append">.frp.one</template>
                </el-input>
              </el-form-item>
            </el-col>
            <el-col :span="24" v-if="formData.choose === '新建域名'">
              <span class="jz">此操作将消耗300积分</span>
            </el-col>
          </div>
          <el-col :span="24" style="margin-top: 20px;">
            <el-collapse v-model="activeName" accordion>
              <el-collapse-item>
                <template slot="title">
                  <span>高级设置</span>
                </template>
                <el-col :span="24">
                  <el-col :xs="24" :sm="12">
                    <el-form-item label="数据加密" prop="encryption">
                      <el-switch v-model="formData.encryption"></el-switch>
                    </el-form-item>
                  </el-col>
                  <el-col :xs="24" :sm="12">
                    <el-form-item label="数据压缩" prop="compression">
                      <el-switch v-model="formData.compression"></el-switch>
                    </el-form-item>
                  </el-col>
                  <el-col :span="24">
                    <el-form-item label="额外参数" prop="ap">
                      <el-input type="textarea" v-model="formData.ap"></el-input>
                    </el-form-item>
                  </el-col>
                </el-col>
              </el-collapse-item>
            </el-collapse>
          </el-col>
        </el-form>
      </el-row>
      <div slot="footer">
        <!-- 添加“随机外网端口”按钮 -->
        <el-button @click="generateRandomPort">随机外网端口</el-button>
        <!-- 添加“随机隧道名”按钮 -->
        <el-button @click="generateRandomTunnelName">随机隧道名</el-button>
        <el-button @click="close">取消</el-button>
        <el-button type="primary" @click="handleConfirm" v-loading="loadingqd">确定</el-button>
      </div>
    </el-dialog>
    <el-dialog title="选择节点" :visible.sync="xznode" :before-close="handleClose" :width="dialogWidth">
      <el-alert style="margin-top: -30px;" title="为确保您的体验，请尽量选择负载低，距离近的节点。" type="success" show-icon>
      </el-alert>
      <div style="margin-top: 10px; display: flex;">
        <el-checkbox-group v-model="quanx">
          <el-checkbox label="UDP"></el-checkbox>
          <el-checkbox label="会员"></el-checkbox>
        </el-checkbox-group>
        <div style="margin-left: 15px; margin-top: -4px;">
          <el-radio-group v-model="jianzhan" size="mini">
            <el-radio-button label="">全部</el-radio-button>
            <el-radio-button label="可建站">可建站</el-radio-button>
            <el-radio-button label="不可建站">不可建站</el-radio-button>
          </el-radio-group>
        </div>
        <div style="margin-left: 15px; margin-top: -4px;">
          <el-radio-group v-model="guojia" size="mini">
            <el-radio-button label="全部">全部</el-radio-button>
            <el-radio-button label="中国">中国</el-radio-button>
            <el-radio-button label="海外">海外</el-radio-button>
          </el-radio-group>
        </div>
      </div>
      <el-row :gutter="10" v-loading="loadingjd">
        <el-col v-for="(item, index) in filteredApiData" :key="index" :xs="24" :md="12" :xl="8">
          <el-card :class="{ 'selected-node': selectedNodeIndex === index }" style="margin-top: 10px;" shadow="hover"
            @click.native="handleCardClick(index, item)">
            <span style="color: rgba(0, 0, 0, 0.8);">#{{ item.id }}</span>
            <el-divider direction="vertical"></el-divider>
            <span style="font-weight:bold;">{{ item.name }}</span>
            <el-tag v-if="item.nodegroup === 'vip'" type="warning" style="float: right; margin-top: -3px;">VIP</el-tag>
            <div style="margin-top: 16px">
              <el-tag v-if="item.udp === 'true'" effect="plain" style="border-radius: 8px;">
                <i class="el-icon-check"></i>
                UDP
              </el-tag>
              <el-tag v-if="item.web === 'yes'" type="success" effect="plain" style="border-radius: 8px;">
                <i class="el-icon-connection"></i>
                建站
              </el-tag>
              <el-tag v-if="item.fangyu === 'false'" type="warning" effect="plain" style="border-radius: 8px;">
                <i class="el-icon-warning-outline"></i>
                无防
              </el-tag>
            </div>
            <div style="margin-top: 16px; color: rgba(0, 0, 0, 0.7);">{{ item.notes }}</div>
          </el-card>
        </el-col>
      </el-row>
      <!-- <span slot="footer" class="dialog-footer">
        <el-button @click="xznode = false">取 消</el-button>
        <el-button type="primary" @click="confirmSelection">确 定</el-button>
      </span> -->
    </el-dialog>
  </div>
</template>

<script>
import axios from 'axios';
import { getToken } from '../../utils/setToken.js'
export default {
  inheritAttrs: false,
  components: {},
  props: [],
  data() {
    return {
      encryption: false,
      compression: false,
      quanx: ['UDP'],
      jianzhan: '',
      guojia: '全部',
      selectedNodeName: '', // 储存选中节点的名称
      NodeDomain: '', // 储存选中节点的ip
      xznode: false, // 节点选择对话框
      handleClose: null, // 初始化创建隧道的对话框
      apiData: [], // 初始化节点选择的apiData
      dialogVisible: false, // 创建隧道对话框
      loadingqd: false, // 确定创建按钮加载动画
      selectedNodeIndex: -1, // 初始化为-1，表示没有选中的节点
      loadingjd: false,
      domainNameLabel: '', // 默认Label
      loading_handleQuery: false,
      hasError: false,
      hasCname: false,
      hasSrv: false,
      haschax: false,
      intranetPort: [{
        intranetPortLabel: '自定义端口请直接输入数字',
        intranetPortDisabled: true
      }, {
        intranetPortValue: 'FTP',
        intranetPortLabel: '21'
      }, {
        intranetPortValue: 'SSH',
        intranetPortLabel: '22'
      }, {
        intranetPortValue: 'HTTPweb',
        intranetPortLabel: '80'
      }, {
        intranetPortValue: 'HTTPSweb',
        intranetPortLabel: '443'
      }, {
        intranetPortValue: 'MySQL',
        intranetPortLabel: '3306'
      }, {
        intranetPortValue: 'RDP',
        intranetPortLabel: '3389'
      }, {
        intranetPortValue: '群晖DMS',
        intranetPortLabel: '5001'
      }, {
        intranetPortValue: '铁锈战争联机',
        intranetPortLabel: '5123'
      }, {
        intranetPortValue: '像素工厂联机',
        intranetPortLabel: '6567'
      }, {
        intranetPortValue: '幻兽帕鲁服务器',
        intranetPortLabel: '8211'
      }, {
        intranetPortValue: '泰拉瑞亚联机',
        intranetPortLabel: '7777'
      }, {
        intranetPortValue: '基岩版MC服务器',
        intranetPortLabel: '19132'
      }, {
        intranetPortValue: 'JavaMC服务器',
        intranetPortLabel: '25565'
      }, {
        intranetPortValue: '异星工厂服务器',
        intranetPortLabel: '34197'
      },],
      intranetPortValue: '',
      formData: { // 节点选择的formdata
        token: '',
        userid: 0,
        localip: '127.0.0.1',
        name: "",
        node: "",
        type: "tcp",
        nport: '',
        dorp: 0,
        ap: '',
        domainNameLabel: '',
        choose: '',
      },
      rules: { // 节点选择的rules
        name: [{
          required: true,
          message: '请输入隧道名称',
          trigger: 'blur'
        }],
        localip: [{
          required: true,
          message: '请输入本地IP',
          trigger: 'blur'
        }],
        node: [{
          required: true,
          message: '请选择节点选择',
          trigger: 'change'
        }],
        type: [{
          required: true,
          message: '请选择端口类型',
          trigger: 'change'
        }],
        nport: [{
          required: true,
          message: '请输入内网端口',
          trigger: 'blur'
        }],
        dorp: [{
          required: true,
          message: '请输入对应数据',
          trigger: 'blur'
        }],
        domainNameLabel: [{
          required: true,
          message: '请选择域名类型',
          trigger: 'blur'
        }],
        choose: [{
          required: true,
          message: '请选择使用已有或新建',
          trigger: 'blur'
        }]
      },
      typeOptions: [{
        "label": "tcp",
        "value": "tcp"
      }, {
        "label": "udp",
        "value": "udp"
      }, {
        "label": "http",
        "value": "http"
      }, {
        "label": "https",
        "value": "https"
      },],
    };
  },
  computed: {
    isHttpOrHttps() {
      return this.formData.type === 'http' || this.formData.type === 'https';
    },
    isTcpOrUdp() {
      return this.formData.type === 'tcp' || this.formData.type === 'udp';
    },
    filteredApiData() {
      return this.apiData.filter(item => this.shouldDisplay(item));
    },
    dialogWidth() {
      // 适配宽度
      return window.innerWidth < 768 ? '100%' : '50%';
    },
  },
  created() {
    this.formData.token = getToken('token');
    this.getUserId();
    this.getData();
    this.generateRandomTunnelName();
    this.generateRandomPort();
  },
  mounted() {
    const savedValues = localStorage.getItem('selectedValues');

    if (savedValues) {
      const parsedValues = JSON.parse(savedValues);
      this.quanx = parsedValues.quanx;
      this.jianzhan = parsedValues.jianzhan;
      this.guojia = parsedValues.guojia;
    }
  },
  methods: {
    handleQuery() {
      // 传递GET参数
      const apiUrl = 'https://cf-v1.uapis.cn/api/Domain_name_query.php';
      const queryParams = {
        domain: this.formData.dorp,
        target_domain: this.NodeDomain,
      };
      this.loading_handleQuery = true;
      // 使用axios发送GET请求并传递参数
      axios.get(apiUrl, { params: queryParams })
        .then(response => {
          if (response.data.status === 'success') {
            // 处理API返回的数据
            if (!response.data.hasCnameToFrpOne && !response.data.hasSrvToFrpOne) {
              this.hasError = true;
              this.hasCname = false;
              this.hasSrv = false;
              this.haschax = true;
              this.$message({
                message: '尚未查询到对应域名解析',
                type: 'warning'
              });
            } else if (response.data.hasCnameToFrpOne) {
              this.hasError = false;
              this.hasCname = true;
              this.hasSrv = false;
              this.haschax = true;
              this.$message({
                message: '已查询到对应CNAME解析',
                type: 'success'
              });
            } else if (!response.data.hasSrvToFrpOne) {
              this.hasError = false;
              this.hasCname = false;
              this.hasSrv = true;
              this.haschax = true;
              this.$message({
                message: '已查询到对应SRV解析',
                type: 'success'
              });
            }
          } else {
            this.$message({
              message: response.message,
              type: "error",
            });
          }
        })
        .catch(error => {
          // 处理错误
          console.error(error);
        })
        .finally(() => {
          this.loading_handleQuery = false; //结束确认按钮加载状态
        });
    },

    shouldDisplay(item) {
      const usergroupIsFree = !this.quanx.includes('会员');
      const udpIsUnchecked = !this.quanx.includes('UDP');
      const jianzhanSelected = this.jianzhan !== '';

      // Determine whether to display based on the selected country
      let shouldDisplayCard = true;

      if (this.guojia === '中国') {
        shouldDisplayCard = item.china === 'yes' && item.area !== '中国香港';
      } else if (this.guojia === '海外') {
        shouldDisplayCard = item.china === 'no' || item.area === '中国香港';
      }

      const usergroup = getToken("usergroup");
      const jianzhanMatches = this.jianzhan === '可建站'
        ? item.web === 'yes'
        : this.jianzhan === '不可建站'
          ? item.web !== 'yes'
          : true;

      let shouldHideNodegroupContent = false;

      if (!usergroupIsFree && usergroup === '免费用户' && item.nodegroup === 'vip') {
        shouldHideNodegroupContent = true;
      }

      return shouldDisplayCard && !shouldHideNodegroupContent &&
        (!udpIsUnchecked || item.udp !== 'true') &&
        (!jianzhanSelected || jianzhanMatches);
    },

    handleCardClick(index, item) {
      // 检查用户实名状态
      const realname = getToken("realname");
      const usergroup = getToken("usergroup");

      if (realname === "未实名") {
        // 用户未实名，显示提示
        this.$notify.error({
          title: '错误',
          message: '您尚未实名，请先进行实名认证'
        });
      } else if (item.nodegroup === "vip" && usergroup === "免费用户") {
        // 节点为 VIP 节点且用户为免费用户，显示提示
        this.$notify({
          title: '警告',
          message: '此节点为VIP节点，您目前的权限组不足',
          type: 'warning'
        });
      } else {
        // 用户已实名，执行您原本的点击逻辑
        this.selectNode(index, item);
      }

      const selectedValues = {
        quanx: this.quanx,
        jianzhan: this.jianzhan,
        guojia: this.guojia
      };

      localStorage.setItem('selectedValues', JSON.stringify(selectedValues));
      this.formData.node = this.selectedNodeName; // 将选中的节点名称赋给 formData.node
      this.dialogVisible = true; // 打开 dialogVisible
      this.xznode = false;
    },

    openNodeDropdown() {
      this.xznode = true;
      this.dialogVisible = false;
    },

    selectNode(index, item) {
      this.selectedNodeIndex = index;
      this.selectedNodeName = item.name;
      this.NodeDomain = item.ip;
    },

    handleTypeChange() {
      if (this.isHttpOrHttps) {
        this.formData.dorp = ''; // 清空formData.dorp
      } else if (this.isTcpOrUdp) {
        this.formData.domainNameLabel = '';
        this.formData.choose = '';
      }
    },

    getPlaceholder(type) {
      return type === 'http' || type === 'https' ? '请输入绑定域名' : '请输入外网端口';
    },

    //获取userid
    async getUserId() {
      const { data: res } = await this.axios.get('userinfo.php', { params: { 'usertoken': this.formData.token } })
      this.formData.userid = res.userid
      // console.log(this.formData.userid);
    },
    // 随机生成一个10000~65535之间的外网端口
    generateRandomPort() {
      this.formData.dorp = Math.floor(Math.random() * 55535) + 10000;
    },

    // 随机生成一个8位的随机隧道名
    generateRandomTunnelName() {
      const chars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
      let randomName = '';
      for (let i = 0; i < 8; i++) {
        const randomIndex = Math.floor(Math.random() * chars.length);
        randomName += chars[randomIndex];
      }
      this.formData.name = randomName;
    },
    getData() {
      this.loadingjd = true;
      axios.get("https://cf-v1.uapis.cn/api/unode.php")
        .then((response) => {
          // 将API返回的节点数据绑定到nodeOptions数组
          this.apiData = response.data;
        })
        .catch((error) => {
          console.error("Failed to fetch node data:", error);
        })
        .finally(() => {
          this.loadingjd = false;
        });
    },
    onOpen() { },
    onClose() {
      this.$refs['tunnelForm'].resetFields()
    },
    close() {
      this.dialogVisible = false
    },
    handleConfirm() {
      this.$refs['tunnelForm'].validate(valid => {
        if (!valid) return
        this.add()
      })
    },
    async add() {
      this.loadingqd = true;
      this.$refs['tunnelForm'].validate(async valid => {
        if (!valid) return;

        if (this.isHttpOrHttps) {
          const domainNamePattern = /^([0-9a-zA-Z-]{1,}\.)+([a-zA-Z]{2,})$/i;
          if (!domainNamePattern.test(this.formData.dorp)) {
            this.$message.error('请输入有效的域名');
            this.loadingqd = false;
            return;
          }
        } else if (this.isTcpOrUdp) {
          const port = parseInt(this.formData.dorp);
          if (isNaN(port) || port < 10000 || port > 65535) {
            this.$message.error('请输入10000~65535之间的数字');
            this.loadingqd = false;
            return;
          }
        }

        const requestData = {
          ...this.formData,
          encryption: this.formData.encryption ? 'true' : 'false',
          compression: this.formData.compression ? 'true' : 'false',
        };

        try {
          const { data: res } = await this.axios.post('/tunnel.php', requestData);

          if (res.code === 200) {
            location.reload();
            this.$notify({
              title: '成功',
              message: res.error,
              type: 'success'
            });
          } else {
            this.$message.error(res.error);
          }

          this.loadingqd = false;
          this.onClose();
          this.close();
        } catch (error) {
          console.error('Error:', error);
          this.loadingqd = false;
        }
      });
    },

  }
};
</script>

<style>
.banner {
  height: 50px;
  line-height: 50px;
}

.selected-node {
  color: #40a0ff;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.25);
  transition: box-shadow 0.3s ease;
}

.jz {
  display: flex;
  justify-content: center;
}
</style>